import SEO from "../../Assets/solutions/DigitalMarketing/SEO.jpg";
import Analytics from "../../Assets/solutions/DigitalMarketing/Analytics.jpg";
import ContentMarketing from "../../Assets/solutions/DigitalMarketing/ContentMarketing.jpg";
import PPC from "../../Assets/solutions/DigitalMarketing/PPC.jpg";
import EmailMarketing from "../../Assets/solutions/DigitalMarketing/EmailMarketing.jpg";
import SocialMedia from "../../Assets/solutions/DigitalMarketing/SocialMedia.jpg";

const DMWork = [
  {
    img: SEO,
    title: "Search Engine Optimization (SEO)",
    alt: "Search Engine Optimization (SEO)",
    descrition:"Elevate your visibility with our expert SEO services. We optimize your website to rank higher in search engine results, driving organic traffic and enhancing your online presence. Let us help you connect with your audience through targeted keywords and on-page strategies that boost your searchability.",
  },
  {
    img: SocialMedia,
    title: "Social Media Marketing and Advertisement",
    alt: "Social Media Marketing",
    descrition:"Engage and grow your audience with our social media marketing and advertising solutions. Our dedicated team of experts craft tailored campaigns that resonate with your target demographics..",
    platforms: "Meta Ads Manager, Linkedin Marketing, Tiktok Marketing",
  },
  {
    img: ContentMarketing,
    title: "Content Marketing",
    alt: "Content Marketing",
    descrition:"Tell your brand's story through compelling content. Our content marketing services focus on creating high-quality, relevant materials that engage your audience and build trust.",
  },
]
const DMWork2 = [

  {
    img: EmailMarketing,
    title: "Email Marketing",
    alt: "Email Marketing",
    descrition:"Reach your customers directly with our targeted email marketing campaigns. We design personalized newsletters and promotional emails that captivate your audience and encourage engagement. With effective segmentation and analytics, we ensure your messages hit the mark and convert leads into loyal customers. ",
  },
  {
    img: PPC,
    title: "Pay-Per-Click (PPC) Advertising",
    alt: "PPC",
    descrition:"Maximize your advertising budget with our PPC ad solutions. We develop strategic campaigns that target the right audience at the right time, driving instant traffic to your site.",
  },
  {
    img: Analytics,
    title: "Analytics and Reporting",
    alt: "Analytics and Reporting",
    descrition:"Gain valuable insights into your marketing efforts with our analytics and reporting services. We track key metrics to measure the effectiveness of your campaigns.",
  },
];

const digitalMarketingWorks = { DMWork, DMWork2 };

export default digitalMarketingWorks;
