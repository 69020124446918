import './index.css'
import React from 'react';
import { Routes, Route} from "react-router-dom";
import Navbar from './Components/Navbar/Navbar';
import Footer from './Components/Footer/Footer';
import Home from './Pages/Home/Home'
import About from './Pages/About/About'
import Contact from './Pages/Contact/Contact'
import Solutions from './Pages/Solutions/Sollution'
import DigitalMarketing from './Pages/Solutions/DigitalMarketing/DigitalMarketing'
import WebDevelopment from './Pages/Solutions/WebDevelopment/WebDevelopment'
import AppDevelopment from './Pages/Solutions/AppDevelopment/AppDevelopment';
import Aibased from './Pages/Solutions/Ai-Based/Aibased';
import DomainHosting from './Pages/Solutions/DomainHosting/DomainHosting';
import Maintainance from './Pages/Solutions/Maintanance/Maintanance';
import SrtipPricing from './Pages/Srtip/Pricing';
import SrtipRegister from './Pages/Srtip/Register';
import Blogs from './Pages/Blogs/Blogs'
import Blog from './Pages/Blogs/Blog'


function App() {
  return (
    <>
      <Navbar/>
      <Routes>
        <Route exact path="/" element={<Home />} />
        <Route exact path="/about" element={<About />} />
        <Route exact path="/contact" element={<Contact />} />
        <Route exact path="/solutions" element={<Solutions />} />

        {/* services */}
        <Route exact path="/digital-marketing" element={<DigitalMarketing />} />
        <Route exact path="/web-development" element={<WebDevelopment />} />
        <Route exact path="/mobile-app-development" element={<AppDevelopment />} />
        <Route exact path="/maintenance-services" element={<Maintainance />} />
        <Route exact path="/ai-based-solutions" element={<Aibased />} />
        <Route exact path="/domain-and-hosting" element={<DomainHosting/>} />

        {/* blogs */}
        <Route exact path="/blogs" element={<Blogs/>} />
        <Route exact path="/blog" element={<Blog/>} />

        {/* srtip setup */}
        <Route exact  path='/srtip' element={<SrtipPricing />} />
        <Route exact  path='/srtip_registeration' element={<SrtipRegister />} />
      </Routes>
      <Footer/>
    </>
  );
}
export default App;


