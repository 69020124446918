import DigitalMarketing from '../../Assets/Home/Digital_Marketing.png'
import WebDevelopment from '../../Assets/Home/Web_development.png'
import AppDevelopment from '../../Assets/Home/Mobile_Application.png'
import AIBasedSolutions from '../../Assets/Home/Ai_based.png'
import DomainHosting from '../../Assets/Home/Domain_hosting.png'
import Maintenance from '../../Assets/Home/Maintanance.png'

const Home = [
    {
        img: DigitalMarketing,
        title: "Digital Marketing Excellence",
        alt:"DigitalMarketing",
        descrition: "Maximize your online impact with our expert team. Reach the right audience with our data-driven digital marketing. From SEO to PPC, we deliver results that matter and help your brand stand out. Meet, Talk and Crack Deals with the right segment of your market.",
        link: "/digital-marketing"

    },
    {
        img: WebDevelopment,
        title: "Web Development",
        alt:"WebDevelopment",
        descrition: "Bring the best of your brand with a website that stands out. Our expert team develops fast, secure, and visually stunning sites tailored to your needs. Together, we'll create a powerful online experience for your audience.",
        link: "/web-development"

    },
    {
        img: AppDevelopment,
        title: "Mobile Application Development",
        alt:"AppDevelopment",
        descrition: "Ready to put your business ideas in the palms of your customers' hands? Our expert team designs and develops custom apps that are not only visually stunning but also packed with features that keep users coming back for more.",
        link: "/app-development"
    },
    {
        img: AIBasedSolutions,
        title: "AI Based Solutions",
        alt:"AIBasedSolutions",
        descrition: "Imagine a future where your data speaks to you. Our team harnesses the power of AI and machine learning to turn complex data into clear insights, helping you make smarter decisions and drive growth.",
        link: "/ai-based-solutions"
    },
    {
        img: DomainHosting,
        title: "Domain & Hosting",
        alt:"DomainHosting",
        descrition: "Your digital journey starts with the perfect domain. Claim your unique online presence with our hassle-free domain hosting solutions. Experience seamless management, robust security, and 24/7 support to keep your website running smoothly.",
        link: "/domain-and-hosting"

    },
    {
        img: Maintenance,
        title: "Maintenance Services",
        alt:"Maintenance",
        descrition: "Ensure the longevity of your software with our expert maintenance services. We offer tailored solutions that enhance functionality, security, and user satisfaction, so you can focus on growth. Let us help you maximize your software investment.",
        link: "/maintenance-services"
    }
]

export default Home;