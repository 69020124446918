import React from 'react'
import Hero from './Hero'
import Work from './Work'

function Maintanance() {
    return (
        <>
            <Hero />
            <Work />
        </>
    )
}

export default Maintanance