import UserCenteredResearch from '../../Assets/solutions//WebDevelopment/User Centered Research.png'
import Planning from '../../Assets/solutions//WebDevelopment/Planning.png'
import Development from '../../Assets/solutions//WebDevelopment/Front-End Development.png'
import BackEndDevelopment from '../../Assets/solutions//WebDevelopment/Back-End Development.png'
import Integration from '../../Assets/solutions//WebDevelopment/Integration & Deployment.png'
import Launch from '../../Assets/solutions//WebDevelopment/Launch.png'
import UI from '../../Assets/solutions//WebDevelopment/UIUX Design.png'

const BelowSect = [
    {
        img: UserCenteredResearch,
        title: "User Centered Research",
        alt:"User Centered Research",
        descrition: "Step 1"

    },
    {
        img: Planning,
        title: "Planning",
        alt:"Planning",
        descrition: "Step 2"

    },
    {
        img: UI,
        title: "UI/UX Design",
        alt:"UI/UX Design",
        descrition: "Step 3"
    },
    {
        img: Development,
        title: "Front-End Development",
        alt:"Front-End Development",
        descrition: "Step 4"
    },
    {
        img: BackEndDevelopment,
        title: "Back-End Development",
        alt:"Back-End Development",
        descrition: "Step 5"

    },
    {
        img: Integration,
        title: "Integration & Deployment",
        alt:"Integration & Deployment",
        descrition: "Step 6"

    },
    {
        img: Launch,
        title: "Launch",
        alt:"Launch",
        descrition: "Step 7"
    }
]

export default BelowSect;