import React, { useEffect } from 'react';
import AOS from 'aos';
import 'aos/dist/aos.css';
import bg from "../../../Assets/solutions/WebDevelopment/bgimage.jpg";
import laptop from "../../../Assets/solutions/WebDevelopment/laptop.png";
import tick from '../../../Assets/solutions/WebDevelopment/tick.svg'

function Work() {
  useEffect(() => {
    AOS.init({ duration: 1500 });
  }, []);

  return (
    <div className="w-full py-8 relative" style={{
      backgroundImage: `url(${bg})`,
      backgroundSize: 'cover',
      backgroundPosition: 'center',
    }}>
      <div className="absolute inset-0" style={{ backgroundColor: "rgba(0, 0, 0, 0.54)" }} />
      <div className="relative w-full sectionlayout poppins z-10">
        <h2
          style={{ fontSize: 'clamp(22px,2.5vw,42px)' }}
          className='text-white uppercase font-bold text-center'
          data-aos='fade-up'
        >
          Service Description
        </h2>
        <p
          className='text-[#F8F5F0] text-center py-2 md:w-[80%] max-w-[750px] md:tracking-wider  mx-auto'
          data-aos='fade-up'
        >
          We offer Web Development Services to businesses of all sizes to give them a boost.
        </p>
       
        <div className="flex lg:flex-row flex-col items-center my-10 space-y-12 lg:space-y-0 md:space-x-16">
          <div className='flex justify-center lg:w-[60%] lg:justify-right'>
            <img
              src={laptop}
              alt="Laptop"
              className='rounded-[9px] max-h-[85vh] object-fit max-w-full'
              data-aos='fade-up'
            />
          </div>
          <div className='lg:flex lg:w-[40%]'>
            <ul
              className='grid grid-cols-1 sm:grid-cols-2 lg:space-y-4 font-bold lg:grid-cols-1 text-[#F8F5F0] 
              md:grid-cols-2 '
              style={{ fontSize: 'clamp(20px,1.2vw,24px)' }}
              data-aos='fade-up'

            >
              <li className='flex py-2 items-center'>
                <img src={tick} alt="tick" className='w-[22px] mr-2' />
                <span className='border-b-2 border-[#F8AB45]'>UI/UX Design</span>
              </li>
              <li className='flex py-2 items-center md:pl-16'>
                <img src={tick} alt="tick" className='w-[22px] mr-2' />
                <span className='border-b-2 border-[#F8AB45]'>Front-end Development</span>
              </li>
              <li className='flex py-2 items-center '>
                <img src={tick} alt="tick" className='w-[22px] mr-2' />
                <span className='border-b-2 border-[#F8AB45]'>Back-end Development</span>
              </li>
              <li className=' flex py-2 items-center md:pl-16'>
                <img src={tick} alt="tick" className='w-[22px] mr-2' /> <span className='border-b-2 border-[#F8AB45]'>Full-Stack Development</span>
              </li><li className=' flex py-2 items-center '>
                <img src={tick} alt="tick" className='w-[22px] mr-2' /> <span className='border-b-2 border-[#F8AB45]'>No/Low Code Development</span>
              </li><li className=' flex py-2 items-center md:pl-16'>
                <img src={tick} alt="tick" className='w-[22px] mr-2 ' /> <span className='border-b-2 border-[#F8AB45]'>Cloud Development</span>
              </li>
              <li className=' flex py-2 items-center'>
                <img src={tick} alt="tick" className='w-[22px] mr-2' /> <span className='border-b-2 border-[#F8AB45]'>Website Testing</span>
              </li><li className=' flex py-2 items-center md:pl-16'>
                <img src={tick} alt="tick" className='w-[22px] mr-2' /> <span className='border-b-2 border-[#F8AB45]'>Maintenance & Updates</span>
              </li>
            </ul>
          </div>
        </div>

      </div>
    </div>
  );
}

export default Work;
