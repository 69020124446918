import React, { useEffect } from "react";
import AOS from "aos";
import "aos/dist/aos.css";
import bg from "../../../Assets/solutions/AiBased/belowbg.jfif";
import data from "../../../Data/Solutions/DomainHosting";
function Work() {
  useEffect(() => {
    AOS.init({ duration: 700 });
  }, []);

  return (
    <div
      className="w-full py-4 md:py-8 h-full relative"
      style={{
        backgroundImage: `url(${bg})`,
        backgroundSize: "cover",
        backgroundPosition: "center",
      }}
    >
      <div
        className="absolute inset-0"
        style={{ backgroundColor: "rgba(0, 0, 0, 0.74)" }}
      />
      <div className="relative w-full sectionlayout poppins z-10">
        <div data-aos="fade-up">
          <h2
            style={{ fontSize: "clamp(28px,2vw,34px)" }}
            className="text-white text-center md:w-[80%] mx-auto pt-10 drop-shadow-md uppercase font-bold"
            data-aos="fade-up"
          >
            Comprehensive{" "}
            <span className="text-[#F8AB45]">Domain & Hosting</span> Solutions
            for Unmatched Website Performance
          </h2>
          <p
            className="text-[#F8F5F0] text-center font-light py-2 md:tracking-wider"
            style={{ fontSize: "clamp(17px,1.1vw,21px)" }}
            data-aos="fade-up"
          >
            Our team is fully equipped to help you get the best of your data,
            and the best of AI.
          </p>
        </div>
        <div className="mt-12 grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-y-6 md:gap-12 lg:gap-20">
          {data.map((item, i) => (
            <>
              <div
                key={i}
                className="py-4 px-2 sm:px-4 border-2 border-white rounded-[12px] "
                data-aos="fade-up"
              >
                <div className="flex justify-center items-center flex-col md:grid ">
                  <img
                    src={item.img}
                    alt={item.alt}
                    className="pt-6 w-[40px]"
                  />
                  <h2
                    style={{ fontSize: "clamp(17px,1.2vw,22px)" }}
                    className=" text-[#F8AB45]   pt-8 drop-shadow-md uppercase font-bold"
                  >
                    {item.title}
                  </h2>
                  <p
                    className="text-[#F8F5F0] font-light py-2 text-center md:text-left   md:tracking-wider "
                    style={{ fontSize: "clamp(14px,1vw,17px)" }}
                  >
                    {item.descrition}
                  </p>
                </div>
              </div>
            </>
          ))}
        </div>
      </div>
    </div>
  );
}

export default Work;
