import React, { useEffect } from 'react';
import AOS from 'aos';
import 'aos/dist/aos.css';
import bg from '../../../Assets/solutions/AiBased/belowbg.jfif';

function BelowSection() {
  useEffect(() => {
    AOS.init({ duration: 700 });
  }, []);

  const colorchange = ["Data Science", "Communication", "Methodology", "Brand Exposure", "Client-Centric", "Innovation"];
  
  const data = [
    {
      heading: "Expertise in Data Science",
      des: "Transform your operations with our seamless AI integration services! We utilize advanced tools like TensorFlow and PyTorch to embed AI solutions into your existing systems, automating processes and driving data-driven decisions for enhanced productivity."
    },
    {
      heading: "Seamless Communication",
      des: "Transform your operations with our seamless AI integration services! We utilize advanced tools like TensorFlow and PyTorch to embed AI solutions into your existing systems, automating processes and driving data-driven decisions for enhanced productivity."
    },
    {
      heading: "Modern Methodology",
      des: "Transform your operations with our seamless AI integration services! We utilize advanced tools like TensorFlow and PyTorch to embed AI solutions into your existing systems, automating processes and driving data-driven decisions for enhanced productivity."
    },
    {
      heading: "Increased Brand Exposure",
      des: "Transform your operations with our seamless AI integration services! We utilize advanced tools like TensorFlow and PyTorch to embed AI solutions into your existing systems, automating processes and driving data-driven decisions for enhanced productivity."
    },
    {
      heading: "Client-Centric Approach",
      des: "Transform your operations with our seamless AI integration services! We utilize advanced tools like TensorFlow and PyTorch to embed AI solutions into your existing systems, automating processes and driving data-driven decisions for enhanced productivity."
    },
    {
      heading: "Focus on Innovation",
      des: "Transform your operations with our seamless AI integration services! We utilize advanced tools like TensorFlow and PyTorch to embed AI solutions into your existing systems, automating processes and driving data-driven decisions for enhanced productivity."
    }
  ];
  const applyColorToHeading = (heading) => {
    let coloredHeading = heading;
    colorchange.forEach((keyword) => {
      const regex = new RegExp(`(${keyword})`, "gi"); 
      coloredHeading = coloredHeading.replace(
        regex,
        `<span style="color: #F8AB45">$1</span>`
      );
    });
    return coloredHeading;
  };

  return (
    <div className="w-full py-4 md:py-8 h-full relative" style={{ 
      backgroundImage: `url(${bg})`,
      backgroundSize: 'cover', 
      backgroundPosition: 'center', 
    }}>
      <div className="absolute inset-0" style={{ backgroundColor: "rgba(0, 0, 0, 0.74)" }} />
      <div className="relative w-full sectionlayout poppins z-10">
        <div data-aos="fade-up">
          <h2 
            style={{ fontSize: 'clamp(28px,2vw,34px)' }} 
            className='text-white text-center pt-10 drop-shadow-md uppercase font-bold'
            data-aos="fade-up"
          >
            What Makes <span className="text-[#F8AB45]">Dynamic Leo</span> a Prominent Data And AI Service Provider
          </h2>
          <p 
            className='text-[#F8F5F0] text-center font-light py-2 md:tracking-wider'
            style={{ fontSize: 'clamp(17px,1.1vw,21px)' }}
            data-aos="fade-up"
          >
            Our team is fully equipped to help you get the best of your data, and the best of AI.
          </p>
        </div>
        <div className='mt-12 grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-5'>
          {data.map((item, i) => (
            <div key={i} className='px-4 py-6 border-2 border-white rounded-[8px]' 
            data-aos="fade-up"
            >
              <h2
                style={{ fontSize: "clamp(21px,1.3vw,26px)" }}
                className="text-white tex-center drop-shadow-md uppercase font-bold"
                dangerouslySetInnerHTML={{ __html: applyColorToHeading(item.heading) }} 
              />
              <p
                className="text-[#F8F5F0] font-light pt-3 text-left md:tracking-wider"
                style={{ fontSize: "clamp(14px,0.9vw,17px)" }}
              >
                {item.des}
              </p>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
}

export default BelowSection;
