export const pricingData = [
    {
      id: 1,
      type: 'Static',
      features: ['3 Page Static Website','Hosting & Domain (.com)', 'SSL Certificate', '5 Business Emails', 'Mobile Responsive Design', 'Accounting Software', 'Website Maintenance Service', 'Social Media Integration', 'Renewal Fee: AED500/Year', 'An Administration Fee of AED 100 plus 5% VAT will be applicable.'],
      bgcolor: "#FF9B48",
      pricing:"FREE"
    },
    {
      id: 2,
      type: 'Dynamic',
      features: ['5 Page Dynamic Website','Custom Website Design', 'Hosting & Domain (.com)', 'SSL Certificate', '10 Business Emails', 'SEO Optimization', 'Dynamic & Mobile Responsive Design', 'Accounting Software', 'Website Maintenance Service', 'Social Media Integration', 'Renewal Fee: AED750/Year', 'An Administration Fee of AED 100 plus 5% VAT will be applicable.'],
      bgcolor: "#FF0000",
      pricing:"AED 950"
    },
    {
      id: 3,
      type: 'Ecommerce',
      features: ['E-Commerce Website', 'Advanced Custom Website Design', 'Hosting & Domain (.com)', 'SSL Certificate', '20 Business Emails', 'Dynamic & Mobile Responsive Design', 'Content Management System', 'Payment Gateway Integration', 'Mobile App For Products Mangement', 'Unlimited Product Categories', 'Unlimited Product Upload Capacity', 'Accounting Software', 'Website Maintenance Service', 'Social Media Integration', 'Renewal Fee: AED1000/Year', 'An Administration Fee of AED 100 plus 5% VAT will be applicable.'],
      bgcolor: "#924CFF",
      pricing:"AED 1950"
    }
  ];