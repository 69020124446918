import DomainNameRegistration from '../../Assets/solutions/DomainHosting/DomainNameRegistration.png'
import DNSManagemnt from '../../Assets/solutions/DomainHosting/DNSManagemnt.png'
import NLP from '../../Assets/solutions/DomainHosting/NLP.png'
import DomainTransfer from '../../Assets/solutions/DomainHosting/DomainTransfer.png';
import SSL from '../../Assets/solutions/DomainHosting/SSL.png';
import EmailHosting from '../../Assets/solutions/DomainHosting/EmailHosting.png';

const DomainandHosting = [
    {
        img: DomainNameRegistration,
        title: "Domain name Registration",
        alt:"Domain name Registration",
        descrition: "Transform your operations with our seamless AI integration services! We utilize advanced tools like TensorFlow and PyTorch to embed AI solutions into your existing systems, automating processes and driving data-driven decisions for enhanced productivity."

    },
    {
        img: DNSManagemnt,
        title: "DNS Management",
        alt:"DNS Management",
        descrition: "Unlock actionable insights with our bespoke AI model development. Our expert team leverages advanced frameworks to create customized models that address your unique challenges, from predictive analytics to personalized recommendations."

    },
    {
        img: NLP,
        title: "NLP Development",
        alt:"NLP Development",
        descrition: "Unlock actionable insights with our bespoke AI model development. Our expert team leverages advanced frameworks to create customized models that address your unique challenges, from predictive analytics to personalized recommendations."
    },
    {
        img: DomainTransfer,
        title: "Domain Transfer assistance",
        alt:"Domain Transfer assistance",
        descrition: "Transform your operations with our seamless AI integration services! We utilize advanced tools like TensorFlow and PyTorch to embed AI solutions into your existing systems, automating processes and driving data-driven decisions for enhanced productivity."
    },
    {
        img: SSL,
        title: "SSL Certificate Integration",
        alt:"SSL Certificate Integration",
        descrition: "Unlock actionable insights with our bespoke AI model development. Our expert team leverages advanced frameworks to create customized models that address your unique challenges, from predictive analytics to personalized recommendations."

    },
    {
        img: EmailHosting,
        title: "Email Hosting",
        alt:"Email Hosting",
        descrition: "Unlock actionable insights with our bespoke AI model development. Our expert team leverages advanced frameworks to create customized models that address your unique challenges, from predictive analytics to personalized recommendations."

    }
]

export default DomainandHosting;