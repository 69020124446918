import React from 'react'
import Solutions from './Solutions'

function Sollution() {
  return (
    <>
    <Solutions/>
    </>
  )
}

export default Sollution