import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import AOS from "aos";
import "aos/dist/aos.css";
import bg from "../../../Assets/Hero/maintanance.jpg";

const HeaderWithBackgroundImage = () => {
  useEffect(() => {
    AOS.init({ duration: 1000 });
  }, []);

  const handleNav = () => {
    document.documentElement.scrollTop = 0;
    document.body.scrollTop = 0;
  };

  return (
    <div className=" relative min-h-[90vh] flex justify-center items-center py-8">
      <div className="sectionlayout">
        <div
          className="absolute top-0 left-0 w-full h-full bg-cover bg-center"
          style={{
            backgroundImage: `url(${bg})`,
          }}
        ></div>
        <div className="absolute inset-0 bg-black bg-opacity-50"></div>
        <div
          className="relative md:w-[90%] lg:w-[75%] mx-auto  z-10 text-center text-[#F8F5F0]"
          data-aos="fade-up"
        >
          <h1
            className="font-bold uppercase poppins"
            style={{ fontSize: "clamp(28px,3vw,50px)" }}
          >
           Got<span className=" text-[#F8AB45]"> Software Maintenance </span>{" "} Concerns? We Have Solutions!
          </h1>
          <p
            className="mt-4 md:w-[90%] mx-auto "
            style={{ fontSize: "clamp(18px,1.5vw,30px)" }}
          >
           Our team provides proactive support, from routine updates and performance monitoring to successful data recovery solutions, ensuring that your systems operate smoothly and securely. With our expertise, you can focus on growing your business while we take care of your software challenges.
          </p>
          <Link to="/contact" onClick={handleNav}>
            <button
              onClick={handleNav}
              className="mt-6 px-6 py-3 text-white mx-auto rounded-md shadow-lg transition duration-300"
              style={{
                background: "linear-gradient(90deg, #607C8A 0%, #BEBEC2 100%)",
              }}
            >
              Start Your Journey
            </button>
          </Link>
        </div>
      </div>
    </div>
  );
};

export default HeaderWithBackgroundImage;
