import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import AOS from "aos";
import "aos/dist/aos.css";
import bg from "../../../Assets/Hero/domain_and_hosting.jpg";

const HeaderWithBackgroundImage = () => {
  useEffect(() => {
    AOS.init({ duration: 1000 });
  }, []);

  const handleNav = () => {
    document.documentElement.scrollTop = 0;
    document.body.scrollTop = 0;
  };

  return (
    <div className="montserrat relative min-h-[90vh] flex justify-center items-center py-8">
      <div className="sectionlayout">
        <div
          className="absolute top-0 left-0 w-full h-full bg-cover bg-center"
          style={{
            backgroundImage: `url(${bg})`,
          }}
        ></div>
        <div
          className="absolute inset-0"
          style={{ backgroundColor: "rgba(0, 0, 0, 0.74)" }}
        />
        <div className="relative md:w-[90%]  mx-auto z-10 text-center text-[#F8F5F0]">
          <h1
            className="font-bold uppercase"
            style={{ fontSize: "clamp(28px,3vw,50px)" }}
            data-aos="fade-up"
          >
            Improve Your Website <br /> with{" "}
            <span className=" text-[#F8AB45]">
              Premium Hosting <br /> Services
            </span>{" "}
          </h1>
          <p
            className="mt-4 text-balance  mx-auto font-medium "
            style={{ fontSize: "clamp(18px,1.5vw,30px)" }}
            data-aos="fade-up"
          >
            Your website deserves the best. Discover our premium hosting
            solutions tailored to your needs. From reliable uptime to top-notch
            security, we've got you covered.
          </p>
          <Link to="/contact" onClick={handleNav}>
            <button
              onClick={handleNav}
              className="mt-6 px-6 py-3 text-white rounded-md shadow-lg transition duration-300"
              style={{
                background: "linear-gradient(90deg, #607C8A 0%, #BEBEC2 100%)",
              }}
              data-aos="fade-up"
            >
              Start Your Journey
            </button>
          </Link>
        </div>
      </div>
    </div>
  );
};

export default HeaderWithBackgroundImage;
