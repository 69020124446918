

const AppDevelopment = [
    {
        title: "Bespoke Mobile App Development",
        des:"Our team specializes in crafting tailor-made mobile applications that align perfectly with your business goals, ensuring a unique user experience across iOS and Android platforms."
    },
    {
        title: "Intuitive User Experience (UX) Design",
        des:"We provide user-centered design solutions that prioritize seamless navigation and engagement, helping to transform casual users into loyal customers through compelling interfaces."
    },
    {
        title: "Comprehensive Quality Assurance & Testing",
        des:"Our dedicated QA team rigorously tests every aspect of your mobile app to identify and resolve issues, ensuring a smooth, bug-free experience that meets the highest industry standards."
    },
    {
        title: "Ongoing App Maintenance & Support",
        des:"We offer proactive maintenance and support services to keep your app updated and running smoothly, ensuring optimal performance and user satisfaction long after launch."
    }, 
    {
        title: "Strategic App Store Optimization (ASO)",
        des:"Our experts employ proven ASO techniques to enhance your app's visibility in app stores, driving organic downloads and improving overall user engagement through effective marketing strategies."
    }
    
]

export default AppDevelopment;