import React, { useEffect } from "react";
import AOS from "aos";
import "aos/dist/aos.css";
import { FaPhoneAlt, FaMapMarkerAlt, FaWhatsapp } from "react-icons/fa";
import bg from "../../Assets/Hero/contact.jfif";

function Content() {
  useEffect(() => {
    AOS.init({ duration: 1000 });
  }, []);

  return (
    <div
      className="w-full py-8 h-full relative overflow-hidden"
      style={{
        backgroundImage: `url(${bg})`,
        backgroundSize: "cover",
        backgroundPosition: "center",
      }}
    >
      <div
        className="absolute inset-0"
        style={{ backgroundColor: "rgba(0, 0, 0, 0.24)" }}
      />
      <h2
        style={{ fontSize: "clamp(26px,3vw,50px)" }}
        className="text-black md:text-center drop-shadow-lg uppercase font-bold px-[1rem] md:px-[2rem]"
      >
        Contact our team
      </h2>
      <p
        className="text-black md:text-center font-medium mx-auto drop-shadow-md px-[1rem] md:px-[2rem]  py-2 md:w-[80%] xl:w-[60%] md:tracking-wider"
        style={{ fontSize: "clamp(17px,1.1vw,22px)" }}
      >
        Got any questions about products or scaling on our platform? We’re here
        to help. Chat with our friendly team 24/7 and get onboard in less than 5
        minutes.
      </p>
      <div className="relative w-full sectionlayout mt-16  md:space-x-8 z-10 flex flex-col-reverse md:flex-row">
        <div
          className="w-full md:w-1/2 mt-8  flex justify-center"
          data-aos="fade-up"
        >
          <div className="w-full bg-white p-8 rounded-xl shadow-xl">
            <form className="mt-6 space-y-6">
              <div className="flex flex-col sm:flex-row space-x-0 sm:space-x-4 space-y-4 sm:space-y-0">
                <div className="w-full sm:w-1/2">
                  <label
                    htmlFor="firstName"
                    className="block text-[#666666] text-[18px] font-semibold mb-2"
                  >
                    First Name
                  </label>
                  <input
                    type="text"
                    id="firstName"
                    placeholder="First Name"
                    className="w-full p-3 border border-gray-300 text-[#666666] text-[14px] rounded-md focus:outline-none focus:border-gray-500"
                  />
                </div>
                <div className="w-full sm:w-1/2">
                  <label
                    htmlFor="lastName"
                    className="block text-[#666666] text-[18px] font-semibold mb-2"
                  >
                    Last Name
                  </label>
                  <input
                    type="text"
                    id="lastName"
                    placeholder="Last Name"
                    className="w-full p-3 border border-gray-300 text-[#666666] text-[14px] rounded-md focus:outline-none focus:border-gray-500"
                  />
                </div>
              </div>

              <div className="">
                <label
                  htmlFor="contactNo"
                  className="block text-[#666666] text-[18px] font-semibold mb-2"
                >
                  Contact No
                </label>
                <input
                  type="text"
                  id="contactNo"
                  placeholder="Contact No"
                  className="w-full p-3 border border-gray-300 text-[#666666] text-[14px] rounded-md focus:outline-none focus:border-gray-500"
                />
              </div>

              <div className="">
                <label
                  htmlFor="message"
                  className="block text-[#666666] text-[18px] font-semibold mb-2"
                >
                  Message
                </label>
                <textarea
                  id="message"
                  placeholder="Message"
                  rows="4"
                  className="w-full p-3 border border-gray-300 text-[#666666] text-[14px] rounded-md focus:outline-none focus:border-gray-500"
                />
              </div>

              <div className="space-y-2">
                <p className="text-[#666666] pb-3 font-bold">Services</p>
                <div className="grid grid-cols-1 sm:grid-cols-2 font-[600] text-[16px] gap-4">
                  <label className="flex items-center">
                    <input type="checkbox" className="mr-2" />
                    Digital Marketing
                  </label>
                  <label className="flex items-center">
                    <input type="checkbox" className="mr-2" />
                    Web Development
                  </label>
                  <label className="flex items-center">
                    <input type="checkbox" className="mr-2" />
                    Mobile App Dev
                  </label>
                  <label className="flex items-center">
                    <input type="checkbox" className="mr-2" />
                    AI-based Solutions
                  </label>
                  <label className="flex items-center">
                    <input type="checkbox" className="mr-2" />
                    Domain & Hosting
                  </label>
                  <label className="flex items-center">
                    <input type="checkbox" className="mr-2" />
                    Maintenance
                  </label>
                </div>
              </div>
              <button className="transition-all flex justify-center items-end   duration-300 ease-in-out hover:text-[#B38E5D] text-white mx-auto py-3 px-5 w-full rounded-[10px] border-2 border-[#B38E5D] hover:bg-transparent bg-[#B38E5D]">
                Send Message
              </button>
            </form>
          </div>
        </div>

        <div className="w-full md:w-1/2 py-6 md:pl-20 flex flex-col space-y-8 text-[#1C1C1C] drop-shadow-md">
          <div data-aos="fade-left">
            <h3 className="text-2xl font-bold">Call us</h3>
            <div className="flex font-semibold items-center mt-2">
              <FaPhoneAlt className="mr-2 text-[1.1rem]" />
              <p>
                <a href="tel:+971509677514">
                  +971 50 967 7514
                </a>
              </p>
            </div>
          </div>

          <div data-aos="fade-left">
            <h3 className="text-2xl font-bold">WhatsApp us</h3>
            <div className="flex font-semibold items-center mt-2">
              <FaWhatsapp className="mr-2 text-[1.4rem]" />
              <p>
                <a href="https://wa.me/971521205514" target="_blank" rel="noopener noreferrer">
                  +971 52 120 5514
                </a>
              </p>
            </div>
          </div>
          <div data-aos="fade-left">
            <h3 className="text-2xl font-bold">Visit us</h3>
            <div className="flex font-semibold items-center mt-2">
              <FaMapMarkerAlt className="mr-2 text-[2rem] md:text-[1.5rem] xl:text-[1.2rem]" />
              <p>Office 21, First Floor, UNIBRICKS Business Center, Salah Al Din Street, Dubai UAE</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Content;
