import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import AOS from "aos";
import "aos/dist/aos.css";
import bg from "../../../Assets/Hero/web_development.jpg";

const Hero = () => {
  useEffect(() => {
    AOS.init({ duration: 1000 });
  }, []);

  const handleNav = () => {
    document.documentElement.scrollTop = 0;
    document.body.scrollTop = 0;
  };

  return (
    <div className="montserrat relative min-h-[90vh] flex justify-center items-center py-8">
      <div className="sectionlayout">
        <div
          className="absolute top-0 left-0 w-full h-full bg-cover bg-center"
          style={{
            backgroundImage: `url(${bg})`,
          }}
        ></div>
        <div className="absolute inset-0" style={{ backgroundColor: "rgba(62, 78, 65, 0.4)" }}></div>
        <div className="absolute inset-0 bg-black bg-opacity-50"></div>
        <div
          className="relative md:w-[90%] lg:w-[75%] mx-auto z-10 text-center text-[#F8F5F0]"
          data-aos="fade-up"
        >
          <h1
            className="font-bold"
            style={{ fontSize: "clamp(28px,3vw,50px)" }}
          >
            Dynamic Mobile Innovations for {" "}
            <span className="border-b-[3px] border-[#F8AB45]">Next-Generation User Engagement</span>
          </h1>
          <p
            className="mt-4 md:w-[85%] mx-auto font-medium"
            style={{ fontSize: "clamp(18px,1.5vw,30px)" }}
          >
            Looking to make your business more accessible and engaging for your users? Our dynamic mobile app solutions are designed to enhance user experiences, drive growth, and keep you ahead in the digital landscape.
          </p>
          <Link to="/contact" onClick={handleNav}>
            <button
              onClick={handleNav}
              className="mt-6 px-6 py-3 text-white rounded-md shadow-lg transition duration-300"
              style={{
                background: "linear-gradient(90deg, #607C8A 0%, #BEBEC2 100%)",
              }}
            >
              Start Your Journey
            </button>
          </Link>

        </div>
      </div>
    </div>
  );
};

export default Hero;
