import servermanagment from "../../Assets/solutions/Maintanance/servermanagment.jpg";
import network from "../../Assets/solutions/Maintanance/networkmonitoring.jfif";
import databackup from "../../Assets/solutions/Maintanance/databackup.jpg";
import systemadministration from "../../Assets/solutions/Maintanance/systemadministration.jpg";
import userauthentication from "../../Assets/solutions/Maintanance/userauthentication.jpg";
import cloudstorage from "../../Assets/solutions/Maintanance/cloudstorage.jpg";

const DMWork = [
  {
    img: databackup,
    title: "Data Backup and Disaster Recovery",
    alt: "Data Backup and Disaster Recovery",
    descrition: "Our engineers specialize in comprehensive data backup and disaster recovery services. We create tailored backup strategies and recovery plans that protect your business-critical information against data loss and system failures.",
  },
  {
    img: servermanagment,
    title: "Server Management",
    alt: "Server Management",
    descrition: "Dynamic Leo provides expert server management to ensure your servers are always optimized and secure. Our team conducts regular updates, performance monitoring, and security patches to minimize downtime and enhance reliability.",
  },
  {
    img: cloudstorage,
    title: "Cloud Storage Solutions",
    alt: "Cloud Storage Solutions",
    descrition: "We offer scalable cloud storage solutions that allow for secure and flexible data management. Now you can access essential files from anywhere, facilitating collaboration while ensuring data security.",
  },

]
const DMWork2 = [
  {
    img: systemadministration,
    title: "System Administration Services",
    alt: "System Administration Services",
    descrition: "Our system administration services provide essential support to keep your IT systems running smoothly. The team will manage system configuration, user permissions, and performance tuning to ensure optimal functionality.",
  },
  {
    img: userauthentication,
    title: "User Authentication Services",
    alt: "User Authentication Services",
    descrition: "Our user authentication services ensure that only authorized individuals have access to your systems and sensitive data. Dynamic Leo employs advanced authentication methods to enhance security and mitigate unauthorized access risks.",
  },

  {
    img: network,
    title: "Network Monitoring & Security",
    alt: "Network Monitoring & Security",
    descrition: "Our engineers offer proactive network monitoring and security services to safeguard your infrastructure from potential threats. We continuously analyze network activity and implement real-time defenses to identify and respond to security incidents swiftly.",
  },
];

const digitalMarketingWorks = { DMWork, DMWork2 };

export default digitalMarketingWorks;
