import React, { useEffect } from "react";
import AOS from "aos";
import "aos/dist/aos.css";
import bg from "../../../Assets/solutions/WebDevelopment/belowSectionbg.jfif";
import BelowSect from '../../../Data/Solutions/WebDevelopmentWork';

function BelowSection() {
  useEffect(() => {
    AOS.init({ duration: 1500 });
  }, []);

  return (
    <div
      className="w-full py-8 relative"
      style={{
        backgroundImage: `url(${bg})`,
        backgroundSize: "cover",
        backgroundPosition: "center",
      }}
    >
      <div
        className="absolute inset-0"
        style={{ backgroundColor: "rgba(0, 0, 0, 0.54)" }}
      />
      <div className="relative w-full sectionlayout poppins z-10">
        <h2 
          style={{ fontSize: 'clamp(22px,2.5vw,42px)' }} 
          className='text-white uppercase drop-shadow-md p-7 font-bold text-center'
          data-aos='fade-up'
        >
          Our <span className="text-[#FF9B48]"> Development </span> Process
        </h2>
       
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-y-14 justify-center items-center py-10">
          {BelowSect.map((items, i) => (
            <div
              key={i}
              className={`flex flex-col justify-center items-center 
                ${i === 6 ? "lg:col-span-3" : ""}`}
            data-aos='fade-up'
            >
              <img
                src={items.img}
                alt={items.alt}
                className="max-w-[180px]"
              />
              <p
                className="text-[#F8AB45] pt-6 font-medium"
                style={{ fontSize: 'clamp(17px,1.1vw,20px)' }}
              >
                {items.descrition}
              </p>
              <h4
                className="text-[#F8F5F0] font-semibold"
                style={{ fontSize: 'clamp(22px,1.8vw,30px)' }}
              >
                {items.title}
              </h4>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
}

export default BelowSection;
