import React, { useState, useEffect } from "react";
import { useLocation } from "react-router-dom";
import { useParams } from "react-router-dom";
import { AiOutlineCheckCircle } from "react-icons/ai";
import axios from "axios";
import dynamicleo from "../../Assets/Registration/dynamicLeo.png";
import company from "../../Assets/Registration/srtip.png";


const CourseForm = () => {
  const [formData, setFormData] = useState({
    agent_first_name: "",
    agent_last_name: "",
    company_name: "",
    first_name: "",
    last_name: "",
    phone_number: "",
    whatsapp_number: "",
    email: "",
    selected_package: "not selected"
  });

  const [isLoading, setIsLoading] = useState(false);
  const [isSuccess, setIsSuccess] = useState(false);

  const location = useLocation();

  useEffect(() => {
    const getQueryParam = (key) => {
      const urlParams = new URLSearchParams(window.location.search);
      return urlParams.get(key);
    };
  
    const selectedPackage = getQueryParam("selected");
    if (selectedPackage) {
      setFormData((prevData) => ({
        ...prevData,
        selected_package: selectedPackage,
      }));
    }
  }, [location]); 

  const handleChange = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value,
    });
  };
  const { package: packageName } = useParams();

  console.log(packageName); 
  const handleSubmit = async (e) => {
    e.preventDefault();

    // Validation to check all fields except whatsapp_number
    const { agent_first_name, agent_last_name, company_name, first_name, last_name, phone_number, email } = formData;
    if (!agent_first_name || !agent_last_name || !company_name || !first_name || !last_name || !phone_number || !email) {
      alert("All fields except WhatsApp number are required.");
      return;
    }

    setIsLoading(true);

    try {
      // Remove whatsapp_number if it's not provided
      const postData = { ...formData };
      if (!postData.whatsapp_number) {
        delete postData.whatsapp_number; // Optional field
      }

      await axios.post("https://api.dynamicleoecommerce.com/api/srtip_customer_registerations", postData, { withCredentials: true });
      setIsLoading(false);
      setIsSuccess(true);
      setFormData({
        agent_first_name: "",
        agent_last_name: "",
        company_name: "",
        first_name: "",
        last_name: "",
        phone_number: "",
        whatsapp_number: "",
        email: "",
        selected_package: "not selected",
      });
    } catch (error) {
      setIsLoading(false);
      console.error("Form submission failed:", error.message);
    }
  };

  const handleCloseSuccessMessage = () => {
    setIsSuccess(false);
  };

  return (
    <>
    <div
      className="max-w-4xl bg-white  my-14 p-8 rounded-lg mx-4 md:mx-auto notosans"
      style={{ boxShadow: "0px 4px 15px rgba(0, 0, 0, 0.2)" }}
      data-aos="fade-up"
    >
      {/* <h2>{packageName}</h2> */}
      <div className="flex md:flex-row flex-col-reverse 0 md:space-x-6 justify-center items-center">
        <img
          src={dynamicleo}
          alt="Dynamic Leo"
          className="max-h-[60px] max-w-[236px] mt-4 md:mt-0"
        />
        <img src={company} alt="Srtip" className="max-h-[70px] max-w-[236px]" />
      </div>
      <h2
        className="text-[#666666] font-bold text-center mt-8"
        style={{ fontSize: "clamp(26px,2.3vw,38px)" }}
      >
        SRTIP Customer Registration
      </h2>
      <form onSubmit={handleSubmit} className="md:space-y-4 py-10">
        <div className="py-2">
          <label className="block text-[#666666] font-bold">
            Agent Name <span className="text-red-500">*</span>
          </label>
          <div className="md:flex md:space-x-4">
            <input
              type="text"
              id="agent_first_name"
              name="agent_first_name"
              placeholder="First Name"
              value={formData.agent_first_name}
              onChange={handleChange}
              className="w-full mt-1 p-2 border-[2px] outline-none border-[#CCCCCC] focus:border-[#B38E5D] rounded-md"
              required
            />
            <input
              type="text"
              id="agent_last_name"
              name="agent_last_name"
              placeholder="Last Name"
              value={formData.agent_last_name}
              onChange={handleChange}
              className="w-full mt-3 md:mt-1 p-2 border-[2px] outline-none border-[#CCCCCC] focus:border-[#B38E5D] rounded-md"
              required
            />
          </div>
        </div>

        <div className="py-2">
          <label className="block text-[#666666] font-bold">
            Company Name <span className="text-red-500">*</span>
          </label>
          <input
            type="text"
            id="company_name"
            name="company_name"
            value={formData.company_name}
            onChange={handleChange}
            placeholder="Your Company Name"
            className="w-full mt-1 p-2 border-[2px] outline-none border-[#CCCCCC] focus:border-[#B38E5D] rounded-md"
            required
          />
        </div>

        <div className="py-2">
          <label className="block text-[#666666] font-bold">
            Customer Name <span className="text-red-500">*</span>
          </label>
          <div className="md:flex md:space-x-4">
            <input
              type="text"
              id="first_name"
              name="first_name"
              placeholder="First Name"
              value={formData.first_name}
              onChange={handleChange}
              className="w-full mt-1 p-2 border-[2px] outline-none border-[#CCCCCC] focus:border-[#B38E5D] rounded-md"
              required
            />
            <input
              type="text"
              id="last_name"
              name="last_name"
              placeholder="Last Name"
              value={formData.last_name}
              onChange={handleChange}
              className="w-full mt-3 md:mt-1 p-2 border-[2px] outline-none border-[#CCCCCC] focus:border-[#B38E5D] rounded-md"
              required
            />
          </div>
        </div>

        <div className="py-2">
          <label className="block text-[#666666] font-bold">
            Customer Phone Number <span className="text-red-500">*</span>
          </label>
          <input
            type="text"
            id="phone_number"
            name="phone_number"
            value={formData.phone_number}
            onChange={handleChange}
            placeholder="Your Phone Number"
            className="w-full mt-1 p-2 border-[2px] outline-none border-[#CCCCCC] focus:border-[#B38E5D] rounded-md"
            required
          />
        </div>

        <div className="py-2">
          <label className="block text-[#666666] font-bold">
            Customer WhatsApp 
          </label>
          <input
            type="text"
            id="whatsapp_number"
            name="whatsapp_number"
            value={formData.whatsapp_number}
            placeholder="If applicable"
            onChange={handleChange}
            className="w-full mt-1 p-2 border-[2px] outline-none border-[#CCCCCC] focus:border-[#B38E5D] rounded-md"
          />
        </div>

        <div className="py-2">
          <label className="block text-[#666666] font-bold">
            Customer Email <span className="text-red-500">*</span>
          </label>
          <input
            type="email"
            id="email"
            name="email"
            placeholder="Enter Your Email"
            value={formData.email}
            onChange={handleChange}
            className="w-full mt-1 p-2 border-[2px] outline-none border-[#CCCCCC] focus:border-[#B38E5D] rounded-md"
            required
          />
        </div>

        <div className="flex justify-end">
          <button
            type="submit"
            className="bg-[#B38E5D] font-bold text-[15px] md:text-[17px] text-white px-4 py-2 duration-300 ease-in-out transition-all rounded-md hover:bg-[#88602d]"
            disabled={isLoading}
          >
            {isLoading ? "Submitting..." : "Submit"}
          </button>
        </div>
      </form>

      {isSuccess && (
        <div className="fixed top-0 left-0 right-0 bottom-0 bg-black bg-opacity-50 flex justify-center items-center">
          <div className="bg-white p-6 rounded-lg shadow-md text-center relative">
            <AiOutlineCheckCircle className="text-[#B38E5D] text-6xl mx-auto" />
            <p className="mt-4 text-lg font-bold text-[#666666]">
              Registered Successfully!
            </p>
            <button
              className="mt-4 bg-[#B38E5D] text-white px-4 py-2 rounded-md hover:bg-[#88602d] transition-all duration-300 ease-in-out"
              onClick={handleCloseSuccessMessage}
            >
              Close
            </button>
          </div>
        </div>
      )}
    </div>
    </>
  );
};

export default CourseForm;
