import React, { useEffect } from "react";
import AOS from "aos";
import "aos/dist/aos.css";
import bgimage from "../../../Assets/solutions/DigitalMarketing/bgimage.jfif";
import DigitalMarketingWork from "../../../Data/Solutions/Maintanance";

const Work = () => {
  useEffect(() => {
    AOS.init({ duration: 1000 });
  }, []);

  const { DMWork, DMWork2 } = DigitalMarketingWork;

  return (
    <div
      className="w-full bg-cover bg-no-repeat poppins"
      style={{ backgroundImage: `url(${bgimage})` }}
    >
      <div style={{ backgroundColor: "#00000080" }}>
        <div className="w-full sectionlayout py-16">
          <h6
            className="font-bold montserrat leading-[50px]  uppercase drop-shadow-md pt-12 mb-6 text-center  text-white lg:w-[70%] w-full mx-auto relative"
            style={{ fontSize: "clamp(28px,2vw,34px)" }}
          >
           Comprehensive  <span className="relative text-[#FF9B48] ">DDomain & Hosting</span>{" "}
           Solutions for Unmatched Website Performance
          </h6>
         

          <div className="space-y-4 md:space-y-20 py-12">
            <div className="grid grid-cols-1  md:grid-cols-3 gap-x-4">
              <div className="flex flex-col space-y-4 h-full">
                {DMWork.slice(1, 3).map((item, index) => (
                  <div
                    className="relative group "
                    key={index}
                    data-aos="fade-up"
                  >
                    <img
                      className="w-full object-cover rounded-[13px]"
                      src={item.img}
                      alt={item.alt}
                    />
                    <div className="absolute inset-0 bg-black ow-full h-full object-cover opacity-40 group-hover:opacity-70 transition-opacity duration-300 rounded-[13px]"></div>
                    <div className="absolute inset-0 flex flex-col justify-end items-start lg:px-7  text-white px-4 transition-all duration-500">
                      <h2 className="text-sm md:text-lg  font-bold  transform group-hover:-translate-y-4 transition-all duration-500 border-b-[2px] border-[#F8AB45] pb-1">
                        {item.title}
                      </h2>
                      <p className="opacity-0  ease-in-out  transform translate-y-full group-hover:mb-2 group-hover:opacity-100 group-hover:translate-y-0 transition-all duration-500 text-sm xl:text-lg">
                        {item.descrition}
                      </p>
                    </div>
                  </div>
                ))}
              </div>
              <div
                className="relative col-span-1 mt-4 lg:mt-0 md:col-span-2 lg:col-span-2 row-span-2 group"
                data-aos="fade-up"
              >
                <img
                  className="w-full h-[100%] object-cover rounded-[13px]"
                  src={DMWork[0].img}
                  alt={DMWork[0].alt}
                />
                <div className="absolute inset-0 bg-black opacity-40 group-hover:opacity-70 transition-opacity duration-300 rounded-[13px]"></div>
                <div className="absolute inset-0 flex flex-col justify-end items-start lg:px-7  text-white p-4 transition-all duration-500">
                  <h2 className="text-sm md:text-lg border-b-[2px] border-[#F8AB45] pb-1 font-bold md:mb-[-20px] group-hover:mb-[-10px] transform group-hover:-translate-y-4 transition-all duration-500">
                    {DMWork[0].title}
                  </h2>
                  <p className="opacity-0 transform translate-y-full group-hover:mb-2  group-hover:opacity-100 group-hover:translate-y-0 transition-all duration-500 text-sm xl:text-lg">
                    {DMWork[0].descrition}
                  </p>
                </div>
              </div>
            </div>

            <div className="grid grid-cols-1  md:grid-cols-3 gap-x-4">
              <div className="flex flex-col space-y-4 h-full">
                {DMWork2.slice(1, 3).map((item, index) => (
                  <div
                    className="relative group "
                    key={index}
                    data-aos="fade-up"
                  >
                    <img
                      className="w-full object-cover rounded-[13px]"
                      src={item.img}
                      alt={item.alt}
                    />
                    <div className="absolute inset-0 bg-black ow-full h-full object-cover opacity-40 group-hover:opacity-70 transition-opacity duration-300 rounded-[13px]"></div>
                    <div className="absolute inset-0 flex flex-col justify-end items-start lg:px-7  text-white px-4 transition-all duration-500">
                      <h2 className="text-sm md:text-lg  font-bold  transform group-hover:-translate-y-4 transition-all duration-500 border-b-[2px] border-[#F8AB45] pb-1">
                        {item.title}
                      </h2>
                      <p className="opacity-0  ease-in-out  transform translate-y-full group-hover:mb-2 group-hover:opacity-100 group-hover:translate-y-0 transition-all duration-500 text-sm xl:text-lg">
                        {item.descrition}
                      </p>
                    </div>
                  </div>
                ))}
              </div>
              <div
                className="relative col-span-1 mt-4 lg:mt-0 md:col-span-2 lg:col-span-2 row-span-2 group"
                data-aos="fade-up"
              >
                <img
                  className="w-full h-[100%]  object-cover rounded-[13px]"
                  src={DMWork2[0].img}
                  alt={DMWork2[0].alt}
                />
                <div className="absolute inset-0 bg-black opacity-40 group-hover:opacity-70 transition-opacity duration-300 rounded-[13px]"></div>
                <div className="absolute inset-0 flex flex-col justify-end items-start lg:px-7  text-white p-4 transition-all duration-500">
                  <h2 className="text-sm md:text-lg border-b-[2px] border-[#F8AB45] pb-1 font-bold md:mb-[-20px] group-hover:mb-[-10px] transform group-hover:-translate-y-4 transition-all duration-500">
                    {DMWork2[0].title}
                  </h2>
                  <p className="opacity-0 transform translate-y-full group-hover:mb-2  group-hover:opacity-100 group-hover:translate-y-0 transition-all duration-500 text-sm xl:text-lg">
                    {DMWork2[0].descrition}
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Work;
