import React, { useEffect } from 'react';
import AOS from 'aos';
import 'aos/dist/aos.css';
import bg from '../../../Assets/solutions/AppDevelopment/bg.jpg';
import circle from '../../../Assets/solutions/AppDevelopment/circle.svg'
function BelowSection() {
  useEffect(() => {
    AOS.init({ duration: 1000 });
  }, []);

  const data = [
    {
      heading: "Idea Exploration",
      des: "We collaborate with you to understand your vision, goals, and target audience, laying the groundwork for a successful app."
    },
    {
      heading: "Market Research",
      des: "Our team conducts thorough market analysis to identify trends, competitors, and user needs, ensuring your app stands out in the marketplace."
    },
    {
      heading: "Wireframing and Prototyping",
      des: "We create wireframes and prototypes that outline the app's structure and flow, providing a visual guide for functionality and design."
    },
    {
      heading: "Design and Development",
      des: "Our designers craft a visually appealing interface while our developers build the app using the latest technologies for optimal performance."
    }, {
      heading: "Quality Assurance Testing",
      des: "We conduct comprehensive testing to identify and resolve any issues, ensuring a smooth, bug-free experience for users upon launch."
    },
    {
      heading: "Launch and Post-Launch Support",
      des: "After a successful launch, we offer ongoing support and maintenance to keep your app updated and enhance its performance based on user feedback."
    },
  ]
  return (
    <div className="w-full py-8 h-full  relative" style={{
      backgroundImage: `url(${bg})`,
      backgroundSize: 'cover',
      backgroundPosition: 'center',
    }}>
      <div className="absolute inset-0" style={{ backgroundColor: "rgba(0, 0, 0, 0.74)" }} />
      <div className="relative w-full md:flex  md:space-x-16 sectionlayout poppins z-10">
        <div className='md:sticky flex flex-col items-start md:top-[30%] md:w-1/2 md:h-[360px]' data-aos="fade-up">
          <h2
            style={{ fontSize: 'clamp(28px,2vw,34px)' }}
            className=' text-white  pt-10 drop-shadow-md uppercase font-bold'

          >
            Follow Our <span className=" text-[#F8AB45]">Dynamic Mobile App</span>  Process from Concept to Completion
          </h2>
          <p
            className='text-[#F8F5F0] font-light py-2  md:tracking-wider '
            style={{ fontSize: 'clamp(17px,1.1vw,21px)' }}


          >
            Experience a seamless journey where your ideas come to life through innovative design and expert development. Our dedicated team ensures that every step is tailored to meet your unique business needs and user expectations.
          </p>
        </div>
        <div className='md:w-1/2 md:flex items-end flex-col'>
          <div className='mt-6 lg:w-[80%]'>
            {
              data.map((item, i) => (
                <>
                  <div key={i} className='py-4  '
                    data-aos="fade-up"
                  >
                    <div className='flex space-x-2'>
                      <img src={circle} alt="Circle" className='h-[22px]' />
                      <h2 style={{ fontSize: 'clamp(20px,1.4vw,26px)' }} className='font-bold text-white'>{item.heading}</h2>
                    </div>
                    <p className='text-[#F8F5F0] font-light mt-2' style={{ fontSize: 'clamp(14px,1.1vw,20px)' }}>
                      {item.des}
                    </p>
                  </div>
                </>
              ))
            }
          </div>
        </div>
      </div>
    </div>
  );
}

export default BelowSection;
