import React, { useEffect } from "react";
import AOS from "aos";
import "aos/dist/aos.css";
import bg from "../../../Assets/Hero/appdevelopment.jpg";
import laptop from "../../../Assets/solutions/AppDevelopment/AppDevelopment.jpg";
import tick from "../../../Assets/solutions/WebDevelopment/tick.svg";
import DMWork from "../../../Data/Solutions/AppDevelopment";

function Work() {
  useEffect(() => {
    AOS.init({ duration: 1000 });
  }, []);

  return (
    <div
      className="w-full py-8 h-full relative overflow-hidden"
      style={{
        backgroundImage: `url(${bg})`,
        backgroundSize: "cover",
        backgroundPosition: "center",
      }}
    >
      <div
        className="absolute inset-0"
        style={{ backgroundColor: "rgba(0, 0, 0, 0.54)" }}
      />
      <div className="relative w-full sectionlayout poppins z-10">
        <h2
          style={{ fontSize: "clamp(22px,2.5vw,42px)" }}
          className="text-white pt-10 drop-shadow-md uppercase font-bold "
          data-aos="fade-right"
        >
          Reimagine Your Business Potential <br /> with a Functional Mobile App
        </h2>
        <p
          className="text-[#F8F5F0] font-light py-2 md:w-[80%]  md:tracking-wider "
          style={{ fontSize: "clamp(15px,1.2vw,21px)" }}
          data-aos="fade-right"
        >
          In today's digital landscape, a functional mobile app is vital for providing convenient interactions. Dynamic Leo delivers custom mobile app solutions that drive innovation and elevate user experiences.
        </p>
        <div className="flex lg:flex-row flex-col items-center my-10 space-y-12 lg:space-y-0 md:space-x-16">
          <div className="lg:flex lg:w-1/2">
            <ul
              className="grid grid-cols-1 sm:grid-cols-2 sm:gap-x-3 lg:space-y-4 font-bold lg:grid-cols-1 text-[#F8F5F0] 
              md:grid-cols-2 "
            >
              {DMWork.map((item, i) => (
                <>
                  <li className="py-2 " data-aos="fade-right" key={i}>
                    <div className="flex">
                      <img src={tick} alt="tick" className="w-[22px] mr-2" />
                      <h2
                        className="border-b-2  inline border-[#F8AB45]"
                        style={{ fontSize: "clamp(19px,1.4vw,26px)" }}
                      >
                        {item.title}
                      </h2>
                    </div>
                    <p
                      className="font-light mt-3 text-[#F8F5F0]"
                      style={{ fontSize: "clamp(15px,1vw,20px)" }}
                    >
                      {item.des}
                    </p>
                  </li>
                </>
              ))}
            </ul>
          </div>
          <div className="flex justify-center lg:w-1/2 ">
            <img
              src={laptop}
              alt="Laptop"
              className="rounded-[9px] max-h-[85vh] object-fit max-w-full"
              data-aos="fade-left"
            />
          </div>
        </div>
      </div>
    </div>
  );
}

export default Work;
