import al_mubarak_transport from "../Assets/Clients/al_mubarak_transport.jpeg";
import ist from "../Assets/Clients/ist.png";
import srtip from "../Assets/Clients/srtip.png";
import target from "../Assets/Clients/target.jpeg";
import peakeagle from "../Assets/Clients/peakeagle.png";
import logolight from "../Assets/Clients/logo-light.jpeg";
import rightone from "../Assets/Clients/Logo-RightOne.png";
import tawseelah from "../Assets/Clients/Tawseelah_logo.png";
const Clients = [
  {
    img: srtip,
    alt: "srtip",
  },
  {
    img: al_mubarak_transport,
    alt: "al_mubarak_transport",
  },
  {
    img: ist,
    alt: "ist",
  },
  {
    img: rightone,
    alt: "rightone",
  },
  {
    img: logolight,
    alt: "logolight",
  },
  {
    img: target,
    alt: "target",
  },
  {
    img: tawseelah,
    alt: "tawseelah",
  },
  {
    img: peakeagle,
    alt: "peakeagle",
  },
];
export default Clients;
