import React from "react";
import { pricingData } from "../../../Data/webPricingdata";

const Pricing = () => {

  return (
    <div className="my-16 sectionlayout">
      <h2
        className="text-[#FF9B48] drop-shadow-md text-center uppercase font-bold py-16"
        style={{ fontSize: "clamp(32px,2.4vw,38px)" }}
      >
        Our Pricing Plans
      </h2>
      <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 mx-auto w-full gap-x-8 gap-y-16 justify-center items-center pb-20">
        {pricingData.map((item, index) => (
          <div
            key={index}
            className={`flex relative bg-[#F5F5F5] shadow-lg w-full h-full  flex-col ${index === 2 ? "md:col-span-2 lg:col-span-1 md:w-[50%] lg:w-full mx-auto" : ""
              }`}
            style={{ boxShadow: "0px 0px 8px 0px #0A74F340" }}
          >
            <div
              style={{ backgroundColor: item.bgcolor }}
              className="relative text-white text-center px-4 py-5 "
            >
              <h2
                className="font-bold drop-shadow-md"
                style={{ fontSize: "clamp(22px,1.6vw,34px)" }}
              >
                {item.type}
              </h2>
            </div>

            <div className="mt-4 pb-10">
              <ul className="space-y-1.5">
                {item.features.map((feature, featureIndex) => (
                  <li
                    key={featureIndex}
                    className={`flex items-center space-x-2 px-6 pt-1.5 poppins `}
                  >
                    <span>
                      <svg
                        className="w-4 h-4 text-white rounded-full bg-green-500"
                        fill="currentColor"
                        viewBox="0 0 24 24"
                      >
                        <path d="M10 17l-5-5 1.41-1.41L10 14.17l7.59-7.59L19 8l-9 9z" />
                      </svg>
                    </span>
                    <span className="pl-3 text-[14px] ">
                      {feature}
                    </span>
                    <button
                      className="w-[90%] z-10 right-[17px] text-[14px] md:text-[15px] transition-all duration-300 ease-in-out bg-white block py-3 font-semibold rounded-full text-white absolute bottom-[-20px]"
                      style={{
                        border: `2px solid ${item.bgcolor}`,
                        color: item.bgcolor,
                        backgroundColor: "white",
                        margin: "0px",
                      }}
                      onMouseEnter={(e) => {
                        e.target.style.backgroundColor = item.bgcolor;
                        e.target.style.color = "white";
                      }}
                      onMouseLeave={(e) => {
                        e.target.style.backgroundColor = "white";
                        e.target.style.color = item.bgcolor;
                      }}
                    >
                      Select This
                    </button>
                  </li>
                ))}
              </ul>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default Pricing;
