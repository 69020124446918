import "./Navbar.css";
import React from "react";
import { NavLink } from "react-router-dom";
import logo from "../../Assets/It-Solutions-Dynamic-Leo.jpg";
import dropdown from "../../Assets/icons/dropdown.svg";
import Navbar from "../../Data/Navbar";
import business_profile from '../../Assets/businessprofile/Dynamic-Leo-Profile.pdf'

function Desktop() {
  const handleNav = () => {
    document.documentElement.scrollTop = 0;
    document.body.scrollTop = 0;
  };
  const handleLinkClick = () => {
    document.documentElement.scrollTop = 0;
    document.body.scrollTop = 0;
  };

  return (
    <>
      <div className="w-full bg-white hidden lg:block sticky top-0 z-20">
        <div className="sectionlayout flex items-center justify-between poppins text-[#6C6C6C] h-[14vh] border-b" style={{ fontSize: "clamp(14px,0.9vw,18px)" }}>
          <div>
            <NavLink to="/" onClick={handleLinkClick}>
              <img
                className="max-h-[60px] max-w-[236px]"
                src={logo}
                alt="It-Solutions-Dynamic-Leo"
              />
            </NavLink>
          </div>
          <div>
            <div className="flex items-center justify-center hover:cursor-pointer">
              {Navbar.links.map((link, index) =>
                !link.submenu ? (
                  <div key={index}>
                    <NavLink
                      onClick={handleNav}
                      to={link.path}
                      className={({ isActive }) =>
                        `px-5 py-2 rounded-md hover:bg-gray-200 ${isActive ? "font-bold" : "text-gray-500"
                        }`
                      }
                    >
                      {link.name}
                    </NavLink>
                  </div>
                ) : (
                  <div
                    key={index}
                    className="relative group flex dropown_menu_trigger"
                  >
                    <NavLink
                      onClick={handleNav}
                      to={link.path}
                      className={({ isActive }) =>
                        `px-5 py-2 rounded-md hover:bg-gray-200 flex ${isActive ? "font-bold" : "text-gray-500"
                        }`
                      }
                    >
                      {link.name}
                      <div className="flex">
                        <img className="pl-1" src={dropdown} alt="dropdown" />
                      </div>
                    </NavLink>
                    <div className="absolute mt-10 dropown_menu z-[100]">
                      <div className="bg-gray-50 font-normal rounded-md w-[17rem] mt-2">
                        {link.submenu.map((sublink, subIndex) => (
                          <div
                            key={subIndex}
                            className="pl-4 pr-8 py-2 hover:bg-gray-200"
                          >
                            <NavLink
                              onClick={handleNav}
                              to={sublink.path}
                              className={({ isActive }) =>
                                isActive ? "font-bold" : "text-gray-500"
                              }
                            >
                              {sublink.name}
                            </NavLink>
                          </div>
                        ))}
                      </div>
                    </div>
                  </div>
                )
              )}
            </div>
          </div>
          <div>
            <a
              href={business_profile}
              target="_blank"
              rel="noopener noreferrer"
            >
              <button className="px-[17px] py-[7px] text-white transition-all duration-300 ease-in-out border-[#3E4E41] hover:bg-transparent hover:text-[#3E4E41] border-[2px] bg-[#3E4E41] rounded-[10px] font-semibold">
                Business Profile
              </button>
            </a>
          </div>
        </div>
      </div>
    </>
  );
}

export default Desktop;
