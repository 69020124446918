import React from 'react'
import Hero from './Hero'
import Solutions from '../Solutions/Solutions'
import Clients from './Clients';
// import Reviews from './Rewiews';\

function Home() {
  return (
    <>
      <Hero />
      <Solutions />
      <Clients />
      {/* <Reviews /> */}
    </>
  )
}

export default Home
