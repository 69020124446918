import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import AOS from "aos";
import "aos/dist/aos.css";
import bg from "../../../Assets/Hero/aibased.png"

const Hero = () => {
  useEffect(() => {
    AOS.init({ duration: 1000 });
  }, []);

  const handleNav = () => {
    document.documentElement.scrollTop = 0;
    document.body.scrollTop = 0;
  };

  return (
    <div className="montserrat relative min-h-[86vh] flex justify-center items-center xl:items-baseline overflow-hidden">
      <div className="max-w-[1640px] mx-auto">
        <div
          className="absolute top-0 left-0 w-full h-full bg-cover bg-center"
          style={{
            backgroundColor: `rgba(14, 18, 19, 0.96)`,
          }}
        ></div>
        <div className="flex lg:flex-row flex-col ">
          <div
            className="relative px-4 md:px-[2rem] py-8 lg:w-[50%] mx-auto z-10  text-[#F8F5F0]"
            data-aos="fade-right"

          >
            <h1
              className="font-bold text-left xl:mt-16"
              style={{ fontSize: "clamp(26px,2.3vw,38px)" }}
            >
              Step into the Future-
              <span className=" text-[#F8AB45]">Dynamic AI </span>
              <span className="border-b-[3px] border-[#F8AB45]">Integration and Development</span>
              {" "}Tailored to You!

            </h1>
            <p
              className="mt-4  text-[#F8F5F0] leading-relaxed text-left mx-auto font-medium"
              style={{ fontSize: "clamp(18px,1.5vw,30px)" }}
            >
              Wanna advance your system? Our expert team will customize AI integration and model development to elevate your business operations. Transform challenges into opportunities and drive innovation today!          </p>
            <Link to="/contact" onClick={handleNav}>
              <button
                onClick={handleNav}
                className="mt-6 px-6 py-3 text-white rounded-md shadow-lg transition duration-300"
                style={{
                  background: "linear-gradient(90deg, #607C8A 0%, #BEBEC2 100%)",
                }}
              >
                Start Your Journey
              </button>
            </Link>
          </div>
          <div className="h-full flex justify-start items-start z-10">
            <img src={bg} alt="Ai Based" className="" data-aos="fade-up-left" />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Hero;
