import React from 'react'
import Hero from './Hero'

function About() {
  return (
    <>
    <Hero/>
    </>
  )
}

export default About