import React from 'react'
import Content from './Content'

function Contact() {
  return (
    <>
    <Content/>
    </>
  )
}

export default Contact