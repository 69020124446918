import React, { useEffect } from 'react';
import clientsData from '../../Data/Clients';
import 'react-multi-carousel/lib/styles.css';
import AOS from 'aos';
import 'aos/dist/aos.css';

function Clients() {
  useEffect(() => {
    AOS.init({ duration: 1000 });
  }, []);

  return (
    <div className="  pb-12 mt-4">
      <div className="sectionlayout my-12">
        <h2 className="font-bold text-[#C39037] drop-shadow-lg py-14 text-center" style={{ fontSize: "clamp(32px,2.4vw,36px)" }}>
          Our Partners in Tech
        </h2>
        <div className='flex items-center justify-center flex-wrap gap-10' data-aos="fade-up">
          {clientsData.map((client, index) => (
            <img
              src={client.img}
              alt={client.alt}
              className="object-contain w-[80px] h-[70px] md:w-[120px] md:h-[110px] xl:w-[150px] xl:h-[140px]"
            />
          ))}
        </div>
      </div>
    </div>
  );
}

export default Clients;
